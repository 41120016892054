import React, {useState} from 'react';
import NavLink from "./NavLink";
import {NavLinkData} from "./consts";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
// import {useDispatch} from "react-redux";
import logo from "../../../assets/images/logo.png"
import kickstarter from "../../../assets/images/kickstarter.png"
import {useTranslation} from "react-i18next";

function Header() {
    // const dispatch = useDispatch()
    const {t, i18n} = useTranslation()

    const onLanguageSwitch = (event) => {
        // const selected = event.currentTarget.value
        // localStorage.setItem('language', selected)
        // setLanguage(selected)
        // apiAxios.defaults.headers['user-language'] = selected
        // i18n.changeLanguage(selected)
        // dispatch(getArchetypes())
    }
    const [language, setLanguage] = useState(localStorage.getItem('language'))
    return (
        <Navbar fixed="top" expand="lg" className="navbar" variant='dark' style={{top: "10px"}}>
            <Container fluid>
                <Link to='/' className="navbar-brand">
                    <img className="mw-100" src={logo}
                         height="50"
                         alt="an amazing wizard"/>
                </Link>
                <Navbar.Toggle aria-controls="navbar-nav"/>
                <Navbar.Collapse id="navbar-nav" className="justify-content-end">
                    <div className="right-menu">
                        <div className="d-flex w-100 justify-content-between">
                            <div className="l">
                                <span data-href="#about">About</span>
                                <span data-href="#media">Media</span>
                                <span data-href="#about">Features</span>
                                <span data-href="#faq">FAQ</span>
                                <a target="_blank"
                                   href="https://drive.google.com/drive/folders/16DwQ9QtiGMycWAWOqgZOfGN1gz8Fv8_U"
                                   style={{color: '#56b556'}}>PressKit
                                </a>
                            </div>
                            <div className="r">
                                <a target="_blank"
                                   href="https://www.kickstarter.com/projects/anamazingwizard/an-amazing-wizard">
                                    <img src={kickstarter} alt="kickstarter" width="175" style={{marginBottom: "7px"}}/>
                                </a>
                                <a target="_blank"
                                   className="text-uppercase"
                                   href="https://store.steampowered.com/app/1372660/An_Amazing_Wizard/"
                                   style={{color: '#fb265b'}}>Wishlist Now!
                                </a>
                                <a href="#newsletter-bottom" className="text-uppercase text-info">Subscribe</a>
                            </div>
                        </div>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;