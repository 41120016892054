import React, {useEffect} from 'react';
import PageWrapper from "../common/PageWrapper";
import {Trans, useTranslation} from "react-i18next";
import splash from "../../assets/images/wizard_bg.jpg"
import logo from "../../assets/images/logo2.png"
import wizard from "../../assets/images/wizard.png"
import kickstarter from "../../assets/images/kickstarter.png"
import {
    faYoutube,
    faSteamSymbol
} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faGlobe,
    faFeatherAlt,
    faPersonCirclePlus,
    faWandMagic,
    faRunning,
    faUnlockAlt,
    faHammer,
    faFingerprint,
    faSortAmountUp,
    faLaughBeam
} from "@fortawesome/free-solid-svg-icons"
import Accordion from 'react-bootstrap/Accordion';
import {getCsrfToken, getFAQ, getSiteData} from "../api/api";
import {STATUS} from "../api/status";
import parse from 'html-react-parser'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import {Subscribe} from "./Footer";

function TopSection() {
    const bg = {
        background: `url(${splash}) repeat-x 50% 0%`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        opacity: 0.35,
    }
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <React.Fragment>
            <section id="top" className="position-relative">
                <div style={{
                    position: "absolute", width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <div className="top-inner" style={{
                        position: "absolute",
                        width: "100%",
                        zIndex: "10",
                        color: "white",
                        maxWidth: "768px",
                        textAlign: "center",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "40px"
                    }}>
                        <div className="top">
                            <img src={logo} alt="An amazing wizard" style={{maxWidth: "100%"}}/>
                        </div>
                        <div className="mid" style={{
                            fontWeight: "bold",
                            fontSize: "24px",
                            lineHeight: "34px"
                        }}>
                            <p>
                                Reclaim your memories, learn the truth about your power and free
                                yourself from the ever-changing world in this fast paced 2D
                                roguelite!
                            </p>
                        </div>
                        <div className="bottom">
                            <div className="">
                                <a target="_blank"
                                   href="https://www.kickstarter.com/projects/anamazingwizard/an-amazing-wizard"
                                   className="btn btn-outline-primary m-2 top-button"
                                   style={{borderColor: "rgb(86, 181, 86)"}}>
                                    <p className="mb-1">JOIN THE</p>
                                    <img src={kickstarter} alt="" width="200"/>
                                </a>
                                <Button className="btn btn-outline-primary m-2 top-button"
                                        variant=""
                                        onClick={handleShow}
                                        style={{borderColor: "#bb0000"}}>
                                    <div
                                        className="d-flex align-items-center justify-content-center"
                                        style={{gap: "10px", height: "100%"}}>
                                        <FontAwesomeIcon icon={faYoutube} style={{
                                            backgroundColor: "#bb0000",
                                            padding: "5px",
                                            borderRadius: "5px"
                                        }}/>
                                        <span>WATCH TRAILER</span>
                                    </div>
                                </Button>
                                <Modal show={show}
                                       onHide={handleClose}
                                       aria-labelledby="contained-modal-title-vcenter"
                                       centered
                                       dialogClassName="modal-50w"
                                >
                                    <Modal.Header>
                                        <Button variant="outline-light" onClick={handleClose}>
                                            Close
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="yt-iframe container">
                                            <iframe width="100%" height="480"
                                                    src="https://www.youtube.com/embed/yMUFVrNXhvU"
                                                    title="YouTube video player" frameBorder="0"
                                                    className="video"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen>

                                            </iframe>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                            <div>
                                <a target="_blank"
                                   href="https://store.steampowered.com/app/1372660/An_Amazing_Wizard/"
                                   className="btn btn-outline-primary m-2 top-button"
                                   style={{borderColor: "#00adee"}}>
                                    <div
                                        className="d-flex align-items-center justify-content-center"
                                        style={{gap: "10px", height: "100%"}}>
                                        <FontAwesomeIcon icon={faSteamSymbol} style={{
                                            background: "linear-gradient(120deg, #00adee, #000000)",
                                            padding: "5px",
                                            borderRadius: "5px"
                                        }}/>
                                        <span>WISHLIST NOW!</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="top-bg" style={bg}></div>
            </section>
        </React.Fragment>
    )
}

function FeaturesItem({icon, title, content}) {
    return (
        <React.Fragment>
            <div className="text-center">
                <FontAwesomeIcon icon={icon}/>
                <h2 className="h4"><b>{title}</b></h2>
                <p>{content}</p>
            </div>
        </React.Fragment>
    )
}

function AboutSection({isLoading, text}) {
    return (
        <React.Fragment>
            <Subscribe id="newsletter-top" style={{backgroundColor: "rgba(0,0,0,.1)"}}/>
            <section id="about">
                <h2 className="fancy"><b>Who is An Amazing Wizard?</b></h2>
                <div className="container text-white mt-5 pb-5">
                    <div className="row about-wrapper">
                        <div className="col-lg-6">
                            {isLoading ? '' : text ? parse(text) : ''}
                        </div>
                        <div className="col-lg-6 d-flex justify-content-center align-items-center">
                            <img src={wizard} alt="An amazing wizard"/>
                        </div>
                    </div>
                    <h3 className="mt-5 text-center features-header">Features</h3>
                    <div className="mt-5 features">
                        <FeaturesItem icon={faGlobe} title="Procedurally Generated Worlds"
                                      content="Explore a unique world every time you play."/>
                        <FeaturesItem icon={faRunning} title="Level Up Your Character"
                                      content="Gain Experience Points for killing enemies. Select one of dozens of perks after leveling up to make you even stronger!"/>
                        <FeaturesItem icon={faPersonCirclePlus} title="7 Playable Characters"
                                      content="Unlock and play as one of the 7 Elemental Wizards!"/>
                        <FeaturesItem icon={faFeatherAlt} title="Enchant Your Spells"
                                      content="Search for Astral Forges to add enchantments to all your Spells and Magic Weapons to add some special effects to them!"/>
                        <FeaturesItem icon={faUnlockAlt} title="Few Difficulty Levels"
                                      content=" Every time you kill the end boss, you will gain the possibility to increase the World Chaos Level and thus change the difficulty level. By doing so you unlock new level, new enemies, challenges, etc. Also all existing enemies gain extra attacks to make all fights even more exiting"/>
                        <FeaturesItem icon={faWandMagic} title="Dozen Of Magic Weapons"
                                      content="Use one of many Magic Weapons to help you survive!"/>
                        <FeaturesItem icon={faHammer} title="200+ Unique Artifacts"
                                      content="Every artifact has its own special use. A lot of them change your Basic Spell behavior or add special abilities. Literally countless combinations."/>
                        <FeaturesItem icon={faFingerprint} title="100+ Spells"
                                      content="Cast Fireballs or Tornados, Summon Creatures, Slow Time, Create Gravity Zones and many, many more…"/>
                        <FeaturesItem icon={faSortAmountUp} title="200+ Permanent Upgrades"
                                      content="Upgrade your starting items or statistics, unlock new wizards, increase your chance for finding better items, add more elements to the world and many more!"/>
                        <FeaturesItem icon={faLaughBeam} title="Hours Of Fun"
                                      content="This one depends on your skill. Something about 50-80 hours or even more!"/>

                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

function Answer({answer}) {
    return (
        <>
            <p>{parse(answer)}</p>
        </>
    )
}

function FaqSection() {
    const [data, setData] = React.useState({
        'loading': true,
        'faq': undefined,
    })

    useEffect(() => {
        getFAQ().then(response => {
            const {data, status} = response;
            if (status === STATUS.HTTP_200_OK) {
                setData(prevState => {
                    return {
                        ...prevState,
                        loading: false,
                        faq: data,
                    }
                })
            }
        }).catch(error => {
            console.log('Unable to fetch data', error)
        })
    }, [])
    return (
        <section id="faq">
            <h2 className="fancy"><b>Frequently Asked Questions</b></h2>
            <div className="container text-white pt-5 pb-5">
                <Accordion defaultActiveKey="0" flush>
                    {
                        data.loading
                            ? ''
                            : Array.isArray(data.faq) ? data.faq?.map(
                                item => <Accordion.Item
                                    key={item.id}
                                    eventKey={item.id}
                                    className="mb-2"
                                    style={{
                                        backgroundColor: 'transparent',
                                        color: 'white'
                                    }}>
                                    <Accordion.Header>{item.question}</Accordion.Header>
                                    <Accordion.Body>
                                        {item?.answers.map(
                                            answer => <Answer
                                                key={answer.id}
                                                answer={answer.content}/>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ) : ''
                    }
                </Accordion>
            </div>
        </section>
    )
}

function ScreenshotsSection({isLoading, files}) {

    return (
        <React.Fragment>
            <section id="media">
                <h2 className="fancy"><b>Media</b>
                    <p className="pt-4"
                       style={{color: "#e8b572", fontSize: "22px", letterSpacing: "5px"}}>BE
                        AMAZING AT
                        MAGIC!</p></h2>
                {
                    isLoading
                        ? 'Loading images...'
                        : <Swiper
                            style={{
                                "--swiper-navigation-color": "#fff",
                                "--swiper-pagination-color": "#fff",
                            }}
                            modules={[Navigation]}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                },
                                480: {
                                    slidesPerView: 1,
                                },
                                640: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20
                                },
                                1200: {
                                    slidesPerView: 2,
                                    spaceBetween: 80
                                },
                            }}
                            navigation
                            centeredSlides={true}
                            initialSlide={1}
                        >
                            {files?.map(file => {
                                return <SwiperSlide key={file.name}>
                                    <div className="position-relative mt-3"
                                         style={{maxWidth: "768px", margin: "0 auto"}}>
                                        <div className="title-wrapper">
                                            <div className="title-inner">
                                                {file.name}
                                            </div>
                                        </div>
                                        <img src={file.image}
                                             alt={file.name}
                                             width="100%"
                                             height="100%"
                                             loading="lazy"
                                             style={{
                                                 border: "3px solid rgba(1,1,1, 0.3)",
                                                 borderRadius: "10px"
                                             }}
                                        />
                                    </div>
                                </SwiperSlide>
                            })}
                        </Swiper>
                }
            </section>
        </React.Fragment>
    )
}

function Home() {
    const {t} = useTranslation()

    function handleBackgroundHeight() {
        const windowHeight = window.innerHeight;
        const backgroundImage = document.getElementById('top-bg')
        backgroundImage.style.height = `${windowHeight}px`;
    }

    function handleScrollToSelector() {
        const scrollTo = (element) => {
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: element.getBoundingClientRect().top + window.scrollY
            });
        }
        document.querySelectorAll('.right-menu span').forEach(element => {
            element.addEventListener('click', () => {
                const scrollToSelector = element.getAttribute('data-href');
                const documentSelector = document.querySelector(scrollToSelector);
                if (documentSelector !== null) {
                    scrollTo(document.querySelector(scrollToSelector));
                } else {
                    window.location.href = `${window.location.origin}/${scrollToSelector}`;
                }
            })
        })
    }

    useEffect(() => {
        handleBackgroundHeight()
        handleScrollToSelector()
    }, [])

    const [data, setData] = React.useState({
        'loading': true,
        'site_data': []
    })

    useEffect(() => {
        getSiteData().then(response => {
            const {data, status} = response;
            if (status === STATUS.HTTP_200_OK) {
                setData(prevState => {
                    return {
                        ...prevState,
                        loading: false,
                        site_data: data[0],
                    }
                })
            }
        }).catch(error => {
            console.log('Unable to fetch data', error)
        })
    }, [])

    useEffect(() => {
        getCsrfToken().then(response => {
        })
    }, [])
    return (
        <React.Fragment>
            <PageWrapper>
                <TopSection/>
                <AboutSection isLoading={data.loading} text={data.site_data.about}/>
                <ScreenshotsSection isLoading={data.loading} files={data.site_data.media}/>
                <FaqSection/>
            </PageWrapper>
        </React.Fragment>
    );
}

export default Home;